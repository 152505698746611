body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/* Casey's Styles */
a, .tabs li.is-active a {color: #dc5b2d;}
a.button {
  color: #dc5b2d;
  background:transparent;
  border-color: rgba(122,122,122,0.2);
  -webkit-transition: 200ms;
  transition: 200ms;
  cursor: pointer;
}
a.button:hover {
  background: #dc5b2d;
  border-color: rgba(255,255,255,1);
  color: #ffffff;
}
.desktop-menu {
  display: none;
}
.tabs i {
  margin-right: .5rem;
}
.entry {
  padding: .75rem;
}
.entry:nth-child(even) {
  background-color:rgba(122,122,122,0.05);
  border-radius: 6px;
}
.job-title {
  margin-bottom:.5rem;
  font-size: 1.2rem;
}
.date {
  color:#9e3106;
}
.level-title {
  font-weight: 900;
  font-size: 1.5rem;
}
li.pagination-link.is-current a {
  color: #ffffff;
}
li.pagination-link.is-current {
  background: #dc5b2d;
  border-color: #eeeeee;
}
li.previous {
  margin-right: 2rem;
}
li.next {
  margin-left: 2rem;
}
.pagination-ellipsis {
  pointer-events: all;
}
.button.is-orange {
  color: #ffffff;
  background: #dc5b2d;
  border-color: #eeeeee;
}
.customer-name {
  font-size: 1.25rem;
  margin-bottom: .25rem;
}
.company-name {
  font-size: .75rem;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: .75rem;
}


.header-content {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height:72px;
  padding-top:9px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  z-index: 9;
  overflow: hidden;
  border-bottom: 1px solid #cccccc;
  box-shadow:0 0 8px rgba(0,0,0,0.1);
}
.header-content.opened {
  height:100%;
  height:-webkit-fill-available;
  padding-bottom: 3rem;
}
.header-content.opened > div {
  text-align: center;
  padding: 0 .75rem;
}
.pad-mobile {
  padding:.75rem;
  margin-top: 84px;
}
.mobile-opener {
  margin-right:2.25rem;
  width:36px;
  height: 20px;
  position: relative;
  justify-content: center;
}
.mobile-opener i {
  opacity:0;
  -webkit-transition: 200ms;
  transition: 200ms;
  -webkit-transition-delay: 400ms;
          transition-delay: 400ms;
  color: #9e3106;
}
.line1, .line2, .line3 {
  width: 100%;
  position: absolute;
  background-color: #9e3106;
  border-radius: 2px;
  height: 4px;
  top: 0;
  left:0;
  -webkit-transition: 200ms;
  transition: 200ms;
}
.line2 {top:8px;-webkit-transition-delay: 100ms;transition-delay: 100ms;}.line3 {top:16px;-webkit-transition-delay: 200ms;transition-delay: 200ms;}
.mobile-opener.clicked i {
  opacity: 1;
  -webkit-transition-delay: 0ms;
          transition-delay: 0ms;
}
.mobile-opener.clicked > span {
  width: 0;
  -webkit-transition-delay:400ms;
          transition-delay:400ms;
}
.user-actions {
  width:100%;
  max-width: 420px;
  margin: 0 auto;
}
.j-center {
  justify-content: center !important;
}
iframe#myId {
  min-height: 100%;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9;
}
.wrap {
  flex-wrap:wrap;
}

@media screen and (max-width:866px) {
  ul.react-tabs__tab-list {
    display: inline-block;
    max-width: 100%;
  }
  ul.react-tabs__tab-list li {
    width: 50%;
    float: left;
  }
  .tabs .react-tabs__tab a {
    align-items: center;
    border-color: #dbdbdb;
    border-style: solid;
    border-width: 1px;
    color: #4a4a4a;
    display: flex;
    justify-content: center;
    margin-bottom: -1px;
    padding: 0.5em 1em;
    vertical-align: top;
    border-radius:0;
    margin-top: 1px;
  }
  .tabs.is-boxed .react-tabs__tab.is-active a {
    background-color: white;
    border:1px solid #dc5b2d;
    border-bottom-color: #dc5b2d !important;
    box-sizing: border-box;
    border-radius:0;
    background-color: #fbefea;
  }
  .ReactModal__Content.ReactModal__Content--after-open {
    height: 100% !important;
    top: 0 !important;
    width: 100% !important;
    left: 0 !important;
  }
}


/* Responsive */
@media screen and (min-width:867px) {
  .mobile-menu {
    display: none;
  }
  .desktop-menu {
    display: flex;
  }
  .site-header {
      width: 240px;
      height: 100VH;
      border-right: 1px solid #eeeeee;
      padding: 1rem;
      position: fixed;
  }
  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height:-webkit-fill-available;
    position: relative;
    box-shadow: none;
    border-bottom: none;
  }
  .wrapper {
    display: flex;
    padding: 0;
  }
  .site-content.container {
    margin-top: 4rem;
    padding-top:0;
    padding-right:0;
    padding-left: 240px;
    padding-bottom: 60px;
  }
  .pad-mobile {
    padding:0;
    margin-top:0;
  }
  a.logo-header {
    display: block;
    margin-top: 3rem;
  }
}


@media screen and (min-width: 1612px) {
  .container {
      max-width: 1464px;
      width: 1464px;
      min-width:75%;
  }
  .site-header {
    width: 300px;
    padding:1rem 3rem;
  }
  .site-content.container {
    padding-left:300px;
  }  
}
